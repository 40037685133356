import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import index from "../page/index.vue";
import Publisher from "../page/Publisher.vue";
import About_Us from "../page/About_Us.vue";
import Group_Business from "../page/Group_Business.vue";
import Contact from "../page/Contact.vue";  
import Policy from "../page/Policy.vue";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let router = new VueRouter({
    mode: 'hash',
    routes: [
        { path: "/", redirect: "/index" },
        { path: "/index", component: index, name: index },
        { path: "/Publisher", component: Publisher, name: Publisher },
        { path: "/About_Us", component: About_Us, name: About_Us },
        { path: "/Group_Business", component: Group_Business, name: Group_Business },
        { path: "/Contact", component: Contact, name: Contact },
        { path: "/Policy", component: Policy, name: Policy },
    ],
})

export default router