<template>
  <div>
    <heard></heard>
    <div class="main">
      <div class="pub_box1">
        <div class="pub_box1_left">
          <img src="../assets/IgniteDsplogo.png">
          <h1 class="pub_box1_title">
            <span class="u-text-custom-color-4">Monetize&nbsp;</span>
            <br>Your Traffic<br>With<span class="u-text-black"> Ignite DSP</span>
          </h1>
          <p class="pub_box1_text">Ignite Media helps Publishers to convert traffic to revenue with our
            intelligent platform, reaching hundreds of advertisers and partners for the best ad experience.&nbsp;</p>
        </div>
        <div class="pub_box1_right">
          <img src="../assets/pubimg1.png">
        </div>
      </div>
      <div class="pub_box2">
        <div class="pub_box2_head">
          <img src="../assets/IgniteADX.png">
          <p>How it works</p>
        </div>
        <img class="pub_box2_img" src="../assets/downloadddd2.png">
      </div>
      <!-- <div class="pub_box3">
        <div style="text-align: center;">
        <img class="pub_box3_head" src="../assets/IgniteDsplogo.png">
        </div>
        <div class="pub_box3_content">
          <div class="content_left">
            <img class="youd0" src="../assets/youd0.png">
            <h1 class="u-text u-text-1">
              <span class="u-text-custom-color-4">50 + Top DSP</span> Budget
            </h1>
          </div>
          <img src="../assets/1rtghbvc.png" class="content_center">
          <div class="content_right">
            <h1>
              <span class="u-text-custom-color-4">200+ direct </span>Advertisers&nbsp; Budget
            </h1>
            <img class="youd1" src="../assets/youd1.png">

          </div>
        </div>
      </div> -->
      <div class="pub_box4">
        <serverCenter></serverCenter>
      </div>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
import serverCenter from "../components/serverCenter.vue";
export default {
  components: {
    heard,
    foot,
    serverCenter
  },
}
</script>

<style scoped>
.pub_box1 {
  width: 1140px;
  max-height: 507px;
  height: 507px;
  margin: auto;
  display: flex;
}

.pub_box1_left {
  max-width: 40%;
  min-width: 40%;
}

.pub_box1_left img {
  width: 216px;
  height: 92px;
  margin-left: 14px;
  margin-top: 23px;
}

.pub_box1_title {
  margin-left: 20px;
  margin-top: -7px;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 1.1;
}

.pub_box1_text {
  margin-left: 20px;
  margin-top: 22px;
  width: 400px;
}

.pub_box1_left .u-active {
  border-width: 2px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  background-image: none;
  text-align: center;
  font-weight: 700;
  border-style: solid;
  padding: 20px 47px 20px 46px;
  border-color: #404040;
  color: #404040;
  background-color: transparent;
  border-radius: 10px;
  display: inline-block;
  margin-top: 35px;
  margin-left: 20px;
}

.pub_box1_left .u-active:hover {
  border-color: #ff5100;
  color: #ffffff;
  background-color: #ff5100;
}

.pub_box1_right {
  max-width: 60%;
  min-width: 60%;
}

.pub_box1_right img {
  width: 100%;
  margin-top: -14px;
}

.pub_box2 {
  color: #111111;
  background-color: #f2f2f2;
  width: 100%;
  text-align: center;
}

.pub_box2_head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.pub_box2_head img {
  width: 240px;
  height: 97px;
  margin-left: 16px;

}

.pub_box2_head p {
  font-size: 2.25rem;
  font-weight: 700;
  margin-left: 21px;
}

.pub_box2_img {
  width: 760px;
  height: 416px;
  margin-top: -10px;
}

.pub_box3 {
  width: 1140px;
  margin: auto;
}

.pub_box3_head {
  width: 216px;
  height: 99px;
  margin: auto;
  margin-top: 16px;
}

.pub_box3_content {
  display: flex;
  margin-top: -41px;

}

.content_left {
  margin-left: 35px;
}

.content_left h1 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1.1;
  margin-left: 37px;
  margin-top: 27px;
}

.content_left .youd0 {
  width: 695px;
  height: 391px;
}

.content_right {
  margin-left: -149px;
}

.content_right h1 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1.1;
  width: 335px;
  margin-left: 78px;
  margin-top: 32px;
}

.content_right .youd1 {
  width: 702px;
  height: 425px;
  margin-left: -232px;
  margin-top: -20px;

}

.content_center {
  width: 450px;
  height: 449px;
  margin-left: -386px;
  margin-top: 34px;

}


.pub_box4 {
  width: 1140px;
  margin: auto;
  margin-top: 58px;
    display: flex;
    margin-bottom: 135px;
}</style>