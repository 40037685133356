<template>
  <div>
    <heard></heard>
    <div class="main">
      <div v-html="htmlContent"></div>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
export default {
  data() {
    return {
      htmlContent: ""
    };
  },
  mounted() {
    fetch("/policy.html") // 直接从 public 目录访问
      .then(response => response.text())
      .then(data => {
        this.htmlContent = data;
      });
  },
  components: {
    heard,
    foot
  },
}
</script>
<style scoped>
body {
    max-width: 1140px;
}
.main {
  /* height: 3413px; */
  width: 1140px;
  margin: auto;
}
</style>

