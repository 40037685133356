<template>
  <div>
    <heard></heard>
    <!-- <div class="Con_box1">
      <div class="Con_box1_content">
        <h5 class=""> Warmly welcome you become our partner</h5>
        <h2 class="">
          <span class="u-text-custom-color-7">Fo</span>&nbsp;cus on Internatioanl<br>
          <span class="u-text-custom-color-4">
            Marketing solution
          </span>
        </h2>
        <p class="Con_box1_text">Sincerely looking for international Publisher &amp; Advertiser<br>To boost your business
          &amp;
          Monetize your traffic !&nbsp;
        </p>
        <a href="mailto:cherry@liftymedia.com" class="Con_box1_btn">Contact us</a>
      </div>
    </div> -->
    <div class="Con_box2">
      <div class="Con_box2_form">
        <h1 class="Con_box2_title">Contact Us</h1>
        <p>Thanks for contacting us! If you're interested in working with us, please fill out the form below.  Someone from the team will get in touch to discuss how Ignite Media Tech can help deliver your needs.</p>
        <form style="padding: 10px" name="Igniteadx form">
          <div class="u-form-group">
            <input type="text" placeholder="Enter your Name" id="name-3b9a" name="name" class="input1" v-model="name">
          </div>
          <div class="form_box">
            <div class="u-form-group ">
              <input type="email" placeholder="EMAIL" id="email-3b9a" name="email" class="input2" v-model="email">
            </div>
            <div class="u-form-group ">
              <input type="tel"
                pattern="\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})"
                placeholder="Phone number" id="phone-0183" name="phone" class="input2" v-model="phone">
            </div>
          </div>
          <div class="u-form-group">
            <textarea placeholder="Enter your message" rows="4" cols="50" id="message-3b9a" name="message"
              v-model="message"></textarea>
          </div>
          <div class="u-form-submit">
            <input type="button" value="submit" @click="sendMessage" class="u-form-btn">
          </div>
        </form>
      </div>
    </div>
    <div class="Con_box3">
      <h1 class=""><b>Create your next Ads Campaign <span class="u-text-white">with us</span></b>
      </h1>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import heard from "../components/heard.vue";
import foot from "../components/foot.vue";
import { contact } from '../../request/api';
export default {
  components: {
    heard,
    foot,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
    }
  },
  methods: {

    sendMessage() {
      if (this.name == "") {
        alert('Please enter your name')
        return false;
      }
      if (this.email == "") {
        alert('Please enter your email address')
        return false;
      }
      if (this.phone == "") {
        alert('Please enter your phone number')
        return false;
      }
      if (this.message == "") {
        alert('Please enter your information')
        return false;
      } else {
        contact({
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: this.message
        }).then(res => {
          console.log(res)
          alert('Submitted successfully')
        })
      }


    }
  }
}
</script>

<style scoped>
.Con_box1 {
  width: 100%;
  height: 538px;
  background-image: url('../assets/diverse-businesspeople-having-meeting.jpg');
  background-size: 100%;
}

.Con_box1_content {
  width: 1140px;
  margin: auto;
}

.Con_box1_content>h5 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #ffffff;
  padding-top: 53px;
}

.Con_box1_content>h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.1;
  color: #3c065e !important;
  padding-top: 23px;
  font-family: Ubuntu, sans-serif !important;
}

.Con_box1_text {
  color: #ffffff;
  margin-top: 20px;
}

.Con_box1_btn {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  background-image: none;
  padding: 13px 26px 15px 25px;
  background: #ffffff;
  color: #000000;
  border-radius: 50px;
  margin-top: 32px;
}

.Con_box1_btn:hover {
  border-color: #ff5100;
  color: #ffffff;
  background-color: #ff5100;
}

.Con_box2_form {
  /* width: 1140px; */
  margin: auto;
  width: 560px;
  padding: 10px;
  text-align: center;
  margin-bottom: 14px;
}

.Con_box2_form .Con_box2_title {
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;

}

.Con_box2_form input,
.Con_box2_form textarea {
  /* text-transform: uppercase; */
  display: block;
  padding: 10px 12px;
  border: none;
  border-radius: 3px;
  color: inherit;
  height: auto;
  outline: none;
  border-bottom: 2px solid #000000;
  border-radius: 0;
  font: inherit;
}

input::placeholder,
textarea::placeholder {
  color: rgb(180, 180, 180);
}

.input1 {
  width: calc(100% - 24px);
}

.form_box {
  display: flex;
  justify-content: space-between;
}

.u-form-group {
  margin-bottom: 20px;
}

.form_box>.u-form-group {
  width: 48%;
  display: block;
}

.form_box>.u-form-group>.input2 {
  width: 91%;
}

.Con_box2_form textarea {
  width: calc(100% - 24px);
}

.u-form-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-form-submit .u-form-btn {
  border-color: #000000;
  color: #000000;
  background-color: transparent;
  background-image: none;
  border-style: solid;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-width: 2px;
  padding: 10px 30px;
}

.u-form-submit .u-form-btn:hover {
  border-color: #ff5100;
  color: #ffffff;
  background-color: #ff5100;
  cursor: pointer;
}


.Con_box3 {
  text-align: center;
  background-image: linear-gradient(to right, #478ac9, #db545a);
  padding: 57px 98px 56px;

}

.Con_box3>h1 {
  line-height: 1.1;
  font-size: 3.125rem;
  color: #ffffff;
  font-family: Roboto, sans-serif !important;
}
</style>